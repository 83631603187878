import React from "react"
import {
  Layout,
  Title,
  ButtonPrimary,
  Banner,
  List,
  ListItem,
  AboutAuthor,
  AboutOrg,
  Awards,
  Award,
  FilmoviGlavniAnimator,
  MedunarodneIzlozbe,
  MedunarodnaIzlozba,
  FilmGlavniAnimator,
  Scenarij,
  Scenariji,
} from "../components/index"
import "../style/app.scss"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import Favicon from "../img/favicon3.png"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types" //for images i think

const knjige = [
  "Six leprous spells and one squared horror",
  "I was a dog and I am again",
  "We adopted grandma and grandpa",
  "All about Eva",
  "Fairy tale about a hat that had a man",
  `Konrad's bird shop`,
  "City on a grain of salt",
  "Fish that could not cry",
  "The bravest mouse in the world",
]

const autori = ["Nena Lončar", "Marijan Lončar"]

const crtaniFilmovi = [`Konrad's bird shop`]

export const query = graphql`
  query {
    riba: file(relativePath: { eq: "riba-koja-nije-umjela-plakati.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mis: file(relativePath: { eq: "najhrabriji-miš-na-svijetu.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    grad: file(relativePath: { eq: "grad-na-zrnu-soli.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    eva: file(relativePath: { eq: "sve-o-evi-vodič-za-sanjare.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    authors: allContentfulAuthor(limit: 3) {
      edges {
        node {
          name
          slug
          id
        }
      }
    }
    books: allContentfulBook(limit: 3) {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
    films: allContentfulFilm {
      edges {
        node {
          name
          id
        }
      }
    }
    promotions: allContentfulPromotion(
      sort: { order: ASC, fields: createdAt }
    ) {
      edges {
        node {
          id
          promoCode
          totalPrice
          subtitleTop
          subtitleBottom
          title
          books {
            availableForBuying
            price
            slug
            title
            cover {
              fluid {
                base64
                sizes
                src
                srcSet
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`

export default ({ data }) => (
  <div className="home-page">
    <Helmet>
      <title>
        Art Organization Creative House – four great titles are looking for a
        place on your shelf!
      </title>
      <meta
        name="title"
        content="Art Organization Creative House – four great titles are looking for a place on your shelf!"
      />
      <meta
        name="description"
        content="Creative House is art organization founded in 2018, which deals with the publication and distribution of quality literary works, the production of original animated films and the organization and implementation of creative workshops and events related to literature and animated film. Our mission is to connect reality and imagination into original art expressions and to expand the space of creative freedom. The founders and main implementers of the program of the Art Organization CREATIVE HOUSE are the multi-award winning writer and screenwriter Nena Lončar and the artist Marijan Lončar."
      />
      <link rel="shortcut icon" type="image/x-icon" href={Favicon} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
    </Helmet>
    <Layout>
      <Link to="/contact" className="banner-wrapper-link">
        <Banner
          className="homepage-banner"
          title={data.promotions.edges[0].node.title}
          subtitleTop={data.promotions.edges[0].node.subtitleTop}
          subtitleBottom={data.promotions.edges[0].node.subtitleBottom}
          price={data.promotions.edges[0].node.totalPrice}
        >
          <div className="home-page-banner-imgs-wrapper">
            {data.promotions.edges[0].node.books.map(book => {
              return <Img fluid={book.cover.fluid} alt={book.title} />
            })}
          </div>
        </Banner>
      </Link>
      <div className="homepage-lists-wrapper" style={{ margin: "50px 20px" }}>
        <List title="Books" link="/books">
          {data.books.edges.map(edge => {
            return (
              <ListItem link={`/${edge.node.slug}`}>{edge.node.title}</ListItem>
            )
          })}
        </List>
        <List title="Authors" link="/authors">
          {data.authors.edges.map(edge => {
            return (
              <ListItem link={`/authors#${edge.node.slug}`}>
                {edge.node.name}
              </ListItem>
            )
          })}
        </List>
        <List
          className="anim-filmovi-list"
          title="Animated Films"
          link="/animated-films"
        >
          {data.films.edges.map(edge => {
            return <ListItem link="/animated-films">{edge.node.name}</ListItem>
          })}
        </List>
      </div>

      <div>
        <Title
          title="Order book"
          subtitle="Contact authors and order your books!"
        />
        <div className="homepage-buy-button-wrapper">
          <Link to="/contact">
            <ButtonPrimary className="homepage-buy-button">
              Order Book
            </ButtonPrimary>
          </Link>
        </div>
        <div className="authors-wrapper">
          <AboutOrg title="Creative House">
            <p>
            "Creative House is an artistic organization founded in 2018 that engages in publishing and distributing high-quality literary works, creating original animated films, and organizing and executing creative workshops and events related to literature and animated film. Our mission is to connect reality and imagination into original artistic expressions and to expand the space for creative freedom. The founders and main implementers of the Creative House artistic organization's program are the multiple award-winning writer and screenwriter Nena Lončar and visual artist Marijan Lončar.
            </p>
          </AboutOrg>
          <AboutAuthor
            className="author-nena"
            idd="nena-loncar"
            title="Nena Lončar"
          >
            <p>
              Screenwriter and multi-award winning writer, she was born in 1968
              in Krapina, where she still lives to this day.
            </p>
            <p>
              A member of some societies, but mostly belongs to her family and
              herself. She loves to read, and by writing she tames her messy
              thoughts.
            </p>
            <p>
              As she creates the stories, Nena plays with words. For her,
              language is like the Universe - miraculous and infinite. With her
              imagination she expands the boundaries of our worlds, and with
              humore she fights against prejudice and stupidity.
            </p>
            <p>
              In her work she explores innovative approaches. Ludicism is not
              foreign to her either. She loves to write for children and enjoys
              it.
            </p>
          </AboutAuthor>
          <Awards>
            <Award>
              award <b>“Zvonko”</b> for a novel{" "}
              <i>“Six leprous spells and one squared horror”</i>
            </Award>
            <Award>
              award <b>“Grigor Vitez”</b> for a novel <i>“All about Eva”</i>
            </Award>
            <Award>
              award<b>“Zvonko”</b> for a novel{" "}
              <i>“I was a dog and I am again”</i>
            </Award>
            <Award>
              International award <b>“The Little Prince”</b> for a novel{" "}
              <i>“All about Eva”</i>
            </Award>
          </Awards>
          <Scenariji>
            <Scenarij>
              Scenarios for<em> four episodes </em> of the animated series “
              <strong>Profesor Balthazar</strong>”, <em>2005.</em>
              <ul className="scenarij-sublist">
                <li>
                  ”<strong>A Small problem</strong>”
                </li>
                <li>
                  ”<strong>Universal Void</strong>”
                </li>
                <li>
                  ”<strong>Cactus Hombre</strong>”
                </li>
                <li>
                  ”<strong>Robot Spirit</strong>”
                </li>
              </ul>
            </Scenarij>
            <Scenarij>
              screenplay for the <em>animated film</em> ”
              <strong>Konrad's bird shop</strong>” (supported by <em>HAVC</em>
              -a), <em>2018.</em>
            </Scenarij>
            <Scenarij>
              screenplay for the <em>animated film</em> “
              <strong>Tuba player</strong>” (supported by <em>HAVC</em>),{" "}
              <em>2019.</em>
            </Scenarij>
          </Scenariji>
          <AboutAuthor
            className="author-marijan"
            idd="marijan-loncar"
            title="Marijan Lončar"
          >
            <p>
              Marijan Lončar (1967) is an award-winning animator and author of
              animated films.
            </p>
            <p>He is a member of HZSU, HDFD and ASIFA.</p>
            <p>
              Over the years, he has worked as the main animator on many films,
              the most important of which are: Little Flying Bears, The Strange
              Adventures of Apprentice Hlapić, Professor Balthazar, Contact,
              Opening credits for the World Animated Film Festival - Animafest.
            </p>
            <p>
              He also made several original animated films: Happy Cricket,
              Banana, Christmas Fairy Tale.
            </p>
          </AboutAuthor>
          <FilmoviGlavniAnimator>
            <FilmGlavniAnimator>“Little flying bears”</FilmGlavniAnimator>
            <FilmGlavniAnimator>
              “The Strange Adventures of Šegrt Hlapić”
            </FilmGlavniAnimator>
            <FilmGlavniAnimator>“Professor Balthazar”</FilmGlavniAnimator>
            <FilmGlavniAnimator>“Contact”</FilmGlavniAnimator>
            <FilmGlavniAnimator>“Banana”</FilmGlavniAnimator>
            <FilmGlavniAnimator>
              “Opening credits for the world festival of animated film
              Animafest”
            </FilmGlavniAnimator>
          </FilmoviGlavniAnimator>
          <MedunarodneIzlozbe>
            <MedunarodnaIzlozba>
              The book "Fairy tale about a hat that had a man" was chosen as one
              of the "Croatian beautiful books" for 2017 as part of the
              international competition "Best Book Design from all over the
              World", and as such participated in the exhibition in Leipzig, and
              will be in the permanent exhibition "Book Art International",
              which opens in the fall as part of the Frankfurt Book Fair.
            </MedunarodnaIzlozba>
            <MedunarodnaIzlozba>
              Exhibition of caricatures in “50th World Gallery of Cartoons
              Skopje 2018“
            </MedunarodnaIzlozba>
          </MedunarodneIzlozbe>
        </div>
      </div>

      <div style={{ marginTop: "40px" }}>
        <Title
          title="Order book"
          subtitle="Contact authors and order your books!"
        />
        <div className="homepage-buy-button-wrapper">
          <Link to="/contact">
            <ButtonPrimary className="homepage-buy-button">
              Order book
            </ButtonPrimary>
          </Link>
        </div>
      </div>
    </Layout>
  </div>
)
